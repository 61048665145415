<template>
  <v-combobox
    v-model="user"
    hide-details
    :items="listUser"
    item-text="name"
    item-value="id"
    outlined
    dense
    placeholder="유저 이름을 검색해주세요."
    @keyup.enter="getListUser"
  />
</template>

<script>
import { defineComponent, reactive, watch, toRefs } from '@vue/composition-api'

export default defineComponent({
  props: { value: [String, Object] },

  setup(props, { root, emit }) {
    const state = reactive({
      listUser: [],
      user: null,
    })

    const getListUser = async (e) => {
      if (!e.target.value) return

      state.listUser = await root.$store.dispatch('app/getListUser', {
        name: e.target.value,
      })

      if (!isNaN(e.target.value)) {
        const listUser = await root.$store.dispatch('app/getListUser', {
          id: Number(e.target.value) ?? undefined,
        })

        state.listUser = [...state.listUser, ...listUser]
      }

      state.listUser = state.listUser.map((user) => ({
        ...user,
        name: `${user.name} (${user.id})`,
      }))
    }

    watch(
      () => props.value,
      () => {
        state.user = props.value
      },
    )

    watch(
      () => state.user,
      () => {
        emit('input', state.user)
      },
    )

    return {
      ...toRefs(state),
      getListUser,
    }
  },
})
</script>
